<template>
<v-dialog v-model="showAlert" width="500" persistent>
    <v-card>
        <v-card-title v-if="title">{{title}}</v-card-title>
        <v-card-text v-html="message"></v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="ok" text @click="showAlert = false">OK</v-btn>
            <slot></slot>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
export default {
    data() {
        return {
            showAlert: false
        }
    },
    watch: {
        active() {
            this.showAlert = true;
        }
    },
    props: {
        title: String,
        message: String,
        active: Number,
        ok: {
            type: Boolean,
            default: true
        }
    }
}
</script>