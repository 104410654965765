<template>
  <v-container>
    <div v-if="$store.state.banners.isLoading">
      <v-card class="mb-5 pa-5">
        <v-skeleton-loader class="mx-auto" type="paragraph"></v-skeleton-loader>
      </v-card>
    </div>
    <v-row v-else-if="banners.length > 0">
      <v-col>
        <v-card class="pa-5 banner-holder" elevation="3">
          <div :class="isBigBanner && isTruncated ? 'is-truncated' : ''">
            <v-row ref="banner-content">
              <v-col
                v-for="(banner, index) in banners"
                cols="12"
                :md="banner.type == 'column' ? '6' : '12'"
                :key="'banner_' + index"
                v-html="banner.content"
              >
              </v-col>
            </v-row>
            <div v-if="isBigBanner" class="read-more-button">
              <v-btn
                rounded
                small
                color="primary"
                @click="isTruncated = !isTruncated"
                >{{ isTruncated ? "Mehr Lesen" : "Weniger" }}</v-btn
              >
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!categorySettings.hideMessages">
      <v-col>
        <v-autocomplete
          v-model="selectedLines"
          :items="lineList"
          chips
          deletable-chips
          label="Linien filtern"
          multiple
          solo
          @change="updateFilter"
          :loading="
            $store.state.messages.isLoading ||
            $store.state.filterGroups.isLoading ||
            $store.state.auth.isLoading
          "
          no-data-text="Keine Daten verfügbar"
        ></v-autocomplete>

        <div
          class="text-subtitle-2 mb-5 login"
          v-if="!$store.state.auth.user && !$store.state.auth.isAuthenticating"
        >
          Melden Sie sich an, um Ihre Einstellungen dauerhaft zu speichern.
          <v-btn @click="signIn" color="primary" outlined rounded small>
            Jetzt Anmelden
          </v-btn>
        </div>

        <div
          v-if="
            $store.state.messages.isLoading ||
            $store.state.auth.isLoading ||
            $store.state.auth.isAuthenticating ||
            $store.state.filterGroups.isLoading ||
            $store.state.banners.isLoading ||
            $store.state.categories.isLoading
          "
        >
          <v-card>
            <v-skeleton-loader
              class="mx-auto"
              type="article"
            ></v-skeleton-loader>
          </v-card>
        </div>
        <div v-else-if="filteredList.length">
          <Message
            v-for="message in filteredList"
            :key="message.id"
            :message="message"
          ></Message>
        </div>
        <div v-else class="text-center text-h6">
          Es liegen keine Meldungen für Ihre Filter vor.
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { firestore } from "../firebase/firebase";
import Message from "./items/Message.vue";

export default {
  components: { Message },
  name: "Home",

  data() {
    return {
      isTruncated: true,
      isBigBanner: false,
      selectedLines: [],
      banner: "",
    };
  },

  computed: {
    categorySettings() {
      if (this.$store.state.categories.categories[this.filterGroupParam]) {
        return this.$store.state.categories.categories[this.filterGroupParam]
      }
      return {}
    },
    filterGroupParam() {
      if (this.$route.params.filterGroupParam) {
        return this.$route.params.filterGroupParam;
      }
      return "main";
    },

    banners() {
      if (this.$store.state.banners.isLoading) {
        return [];
      }
      if (!this.$store.state.banners.banners[this.filterGroupParam]) {
        return [];
      }
      return this.$store.state.banners.banners[this.filterGroupParam];
    },

    lineList() {
      if (this.$store.state.auth.user) {
        return this.$store.state.lineList.lineList
          .map((line) => line.line)
          .sort((a, b) => {
            if (a === b) return 0;
            return a < b ? -1 : 1;
          });
      }

      const lineList = this.messages.reduce((acc, cur) => {
        if (acc.findIndex((item) => item.value == cur.line) == -1) {
          acc.push(cur.line);
        }
        return acc;
      }, []);

      // const filterGroupsArray = this.$store.state.filterGroups.filterGroups.map(
      //   (fg) => fg.description
      // );

      // if (this.selectedLines) {
      //   this.selectedLines.forEach((line) => {
      //     if (!lineList.includes(line)) {
      //       lineList.push(line);
      //     }
      //   });
      // }

      return lineList.sort((a, b) => {
        if (a === b) return 0;
        return a < b ? -1 : 1;
      });
    },

    filteredList() {
      if (this.selectedLines.length) {
        return this.messages
          .filter((message) => this.selectedLines.includes(message.line))
          .sort((a, b) => {
            if (a.visible_to === b.visible_to) return 0;
            return a.visible_to <= b.visible_to ? -1 : 1;
          });
      }
      return this.messages.slice().sort((a, b) => {
        if (a.visible_to === b.visible_to) return 0;
        return a.visible_to <= b.visible_to ? -1 : 1;
      });
    },

    messages() {
      return this.$store.state.messages.messages;
    },

    computedBanner() {
      const banner = this.banner;
      if (this.isTruncated) {
        return banner.length > 500
          ? banner.substr(0, 499) + `&hellip;`
          : banner;
      }
      return banner.replace(/<p/g, "<div").replace(/<\/p/g, "</div");
    },
  },

  watch: {
    "$store.state.auth.user": {
      handler: function (user) {
        if (user) {
          this.$store.dispatch("attachUserDataOnSnapshot");
        }
      },
      immediate: true,
    },

    "$store.state.auth.userData.selectedLines": {
      handler: function (selectedLines) {
        if (this.filterGroupParam == "main") {
          this.selectedLines = selectedLines;
        }
      },
    },

    "$vuetify.breakpoint.mobile": {
      handler: function () {
        this.setBannerHeight();
      },
      immediate: true,
    },

    "$store.state.banners.bannersWatcher": {
      handler: function () {
        this.$nextTick(() => {
          this.setBannerHeight();
        });
      },
    },
  },

  methods: {
    setBannerHeight() {
      if (this.$refs["banner-content"]) {
        this.isBigBanner =
          this.$refs["banner-content"].offsetHeight > 300 &&
          this.$vuetify.breakpoint.mobile;
      }
    },

    signIn() {
      loginNew.auth();
    },

    setSelectedFilterGroups(fgParam) {
      if (this.filterGroupParam) {
        const filteredGroup =
          this.$store.state.filterGroups.filterGroups.filter(
            (fg) => fg.param == fgParam
          );
        if (filteredGroup.length) {
          this.selectedLines = [];
          this.selectedLines = filteredGroup[0].lines;
          if (!this.selectedLines.includes(filteredGroup[0].description)) {
            this.selectedLines.push(filteredGroup[0].description);
          }
        }
      }
    },

    async updateFilter(selectedValues) {
      this.selectedLines = [];
      let index;
      let keyword;
      if (
        this.$store.state.filterGroups.filterGroups.some((fg) => {
          index = selectedValues.indexOf(fg.description);
          keyword = selectedValues[index];
          return index >= 0;
        })
      ) {
        this.selectedLines = this.$store.state.filterGroups.filterGroups.filter(
          (fg) => fg.description == keyword
        )[0]["lines"];
        if (!this.selectedLines.includes(keyword)) {
          this.selectedLines.push(keyword);
        }
      } else {
        this.selectedLines = selectedValues;
      }
      if (this.$store.state.auth.user) {
        await firestore
          .collection("userData")
          .doc(this.$store.state.auth.user)
          .set({ selectedLines: this.selectedLines }, { merge: true });
      }
    },
  },

  mounted() {
    this.$store.dispatch("attachBannersOnSnapshot", this.filterGroupParam);
    this.$store.dispatch("attachCategorySettingsOnSnapshot", this.filterGroupParam);
    this.$store.dispatch("attachLineListOnSnapshot");
    this.$store.dispatch("attachMessagesOnSnapshot");
    this.$store.dispatch("attachFilterGroupsOnSnapshot").then(() => {
      if (this.filterGroupParam)
        this.setSelectedFilterGroups(this.filterGroupParam);
    });
  },
};
</script>
<style>
.banner-holder p {
  margin-bottom: 0;
}

.banner-holder .ql-align-center {
  text-align: center;
}

.banner-holder .ql-align-left {
  text-align: left;
}

.banner-holder .ql-align-right {
  text-align: right;
}

.banner-holder .ql-align-justify {
  text-align: justify;
}

.banner-holder img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.banner-holder .is-truncated {
  max-height: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.banner-holder .read-more-button {
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 40px 0 5px 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #fff);
}

.banner-holder .is-truncated .read-more-button {
  position: absolute;
}
</style>
