import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import banners from './modules/banners'
import categories from './modules/categories'
import filterGroups from './modules/filterGroups'
import lineList from './modules/lineList'
import messages from './modules/messages'

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        auth, banners, filterGroups, lineList, messages, categories
    }
})
