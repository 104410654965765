import Vue from 'vue';
import { firestore } from '../../firebase/firebase'

const state = {
    firestoreListener: {},
    isLoading: false,
    categories: {},
    bannersWatcher: 0
}

const actions = {
    attachCategorySettingsOnSnapshot({ state }, category) {
        return new Promise((resolve, reject) => {
            if (state.categories[category]) {
                resolve(state.categories[category])
                return
            }
            state.isLoading = true
            state.firestoreListener[category] = firestore.collection("categories").doc(category).onSnapshot((doc) => {
				if (doc.exists) {
					Vue.set(state.categories, doc.id, doc.data())
					state.isLoading = false
					resolve(state.categories[category])
				}
				state.isLoading = false
				reject("category_not_found")
			})
        })
    },
    attachAllCategorySettingsOnSnapshot({ state }) {
        return new Promise((resolve, reject) => {
            if (state.categories) {
                resolve(state.categories)
                return
            }
            state.isLoading = true
            state.firestoreListener["all"] = firestore.collection("categories").get().onSnapshot((doc) => {
				if (doc.exists) {
					Vue.set(state.categories, doc.id, doc.data())
					state.isLoading = false
					resolve(state.categories)
				}
				state.isLoading = false
				reject("category_not_found")
			})
        })
    },

    detachCategorySettingsOnSnapshot({ state }, category) {
        if (state.firestoreListener[category]) {
            state.firestoreListener[category]()
            state.firestoreListener[category] = null
        }
    },
}

export default { state, actions }