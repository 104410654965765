import { firestore } from '../../firebase/firebase'

const state = {
    lineList: [],
    firestoreListener: null,
    isLoading: false
}

const actions = {
    attachLineListOnSnapshot({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.lineList)
                return
            }
            state.isLoading = true
            state.firestoreListener = firestore
                .collection("lineData")
                .onSnapshot(docs => {
                    const firebaseLineList = [];
                    docs.forEach((doc) => {
                        firebaseLineList.push({
                            ...doc.data(),
                            id: doc.id,
                        })
                    })
                    state.lineList = firebaseLineList
                    state.isLoading = false
                    resolve(state.lineList)
                }, (error) => {
                    console.log(error.message)
                    reject(error)
                })
        })
    },

    detachLineListOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener()
            state.firestoreListener = null
        }
    }
}

export default { state, actions }
