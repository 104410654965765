var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.$store.state.banners.isLoading)?_c('div',[_c('v-card',{staticClass:"mb-5 pa-5"},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"paragraph"}})],1)],1):(_vm.banners.length > 0)?_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"pa-5 banner-holder",attrs:{"elevation":"3"}},[_c('div',{class:_vm.isBigBanner && _vm.isTruncated ? 'is-truncated' : ''},[_c('v-row',{ref:"banner-content"},_vm._l((_vm.banners),function(banner,index){return _c('v-col',{key:'banner_' + index,attrs:{"cols":"12","md":banner.type == 'column' ? '6' : '12'},domProps:{"innerHTML":_vm._s(banner.content)}})}),1),(_vm.isBigBanner)?_c('div',{staticClass:"read-more-button"},[_c('v-btn',{attrs:{"rounded":"","small":"","color":"primary"},on:{"click":function($event){_vm.isTruncated = !_vm.isTruncated}}},[_vm._v(_vm._s(_vm.isTruncated ? "Mehr Lesen" : "Weniger"))])],1):_vm._e()],1)])],1)],1):_vm._e(),(!_vm.categorySettings.hideMessages)?_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.lineList,"chips":"","deletable-chips":"","label":"Linien filtern","multiple":"","solo":"","loading":_vm.$store.state.messages.isLoading ||
          _vm.$store.state.filterGroups.isLoading ||
          _vm.$store.state.auth.isLoading,"no-data-text":"Keine Daten verfügbar"},on:{"change":_vm.updateFilter},model:{value:(_vm.selectedLines),callback:function ($$v) {_vm.selectedLines=$$v},expression:"selectedLines"}}),(!_vm.$store.state.auth.user && !_vm.$store.state.auth.isAuthenticating)?_c('div',{staticClass:"text-subtitle-2 mb-5 login"},[_vm._v(" Melden Sie sich an, um Ihre Einstellungen dauerhaft zu speichern. "),_c('v-btn',{attrs:{"color":"primary","outlined":"","rounded":"","small":""},on:{"click":_vm.signIn}},[_vm._v(" Jetzt Anmelden ")])],1):_vm._e(),(
          _vm.$store.state.messages.isLoading ||
          _vm.$store.state.auth.isLoading ||
          _vm.$store.state.auth.isAuthenticating ||
          _vm.$store.state.filterGroups.isLoading ||
          _vm.$store.state.banners.isLoading ||
          _vm.$store.state.categories.isLoading
        )?_c('div',[_c('v-card',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"article"}})],1)],1):(_vm.filteredList.length)?_c('div',_vm._l((_vm.filteredList),function(message){return _c('Message',{key:message.id,attrs:{"message":message}})}),1):_c('div',{staticClass:"text-center text-h6"},[_vm._v(" Es liegen keine Meldungen für Ihre Filter vor. ")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }