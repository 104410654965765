<template>
<v-dialog persistent v-model="dialog" width="500">
    <AlertDialog :active="alert.active" :title="alert.title" :message="alert.message"></AlertDialog>
    <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title>Rechteverwaltung</v-card-title>
        <v-list>
            <v-list-group v-for="permission in permissions" :key="permission._id">
                <template v-slot:activator>
                    <v-list-item class="px-0">
                        <v-list-item-avatar v-if="permission.picture">
                            <v-img :src="permission.picture"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-avatar v-else>
                            <v-icon class="grey lighten-1" dark>mdi-account</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{permission.name ? permission.name + " " + permission.family_name : permission._id}}</v-list-item-title>
                            <v-list-item-subtitle>{{permission.aid}}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <v-btn @click="deletePermission(permission)" color="primary" small class="mx-5 mt-1 mb-2">Rechte entfernen</v-btn>
                <v-checkbox v-model="permission.userManagement" label="Rechteverwaltung" hide-details inset class="px-5 my-0" color="primaryLight"></v-checkbox>
                <v-checkbox v-model="permission.messageOfTheDay" label="Message of the day bearbeiten" hide-details inset class="px-5 my-0" color="primaryLight"></v-checkbox>
                <v-btn @click="savePermission(permission)" color="primary" small class="mx-5 mt-2">Speichern</v-btn>
                <v-divider class="my-2"></v-divider>
            </v-list-group>
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark>mdi-account</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-subtitle><v-text-field v-model="addPermissionsId" placeholder="Neue A-Kennung"  outlined dense hide-details color="primaryLight"></v-text-field></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon @click="addPermissions">
                        <v-icon color="primaryLight">mdi-plus</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialog = false">OK</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import {firestore, functions} from '../../firebase/firebase'
import AlertDialog from '../dialogs/AlertDialog.vue'

export default {
    data() {
        return {
            alert: {
                title: "",
                message: "",
                active: 0
            },
            isLoading: false,
            dialog: false,
            permissions: {},
            addPermissionsId: "",
            firebase: {
                listener: null
            }
        }
    },
    methods: {
        async deletePermission(permission) {
            this.isLoading = true;
            try{
                await firestore.collection("permissions").doc(permission._id).delete()
            }catch(err) {
                this.alert.title = "Berechtigungen entfernen"
                this.alert.message = "Beim Entfernen der Berechtigung ist ein Fehler unterlaufen!"
                this.alert.active++
                console.error(err)
            }
            this.isLoading = false;
        },
        async savePermission(permission) {
            this.isLoading = true;
            try{
                await firestore.collection("permissions").doc(permission._id).update({
                    userManagement: permission.userManagement,
                    messageOfTheDay: permission.messageOfTheDay,
                })
            }catch(err) {
                this.alert.title = "Berechtigungen speichern"
                this.alert.message = "Beim Speichern der Berechtigung ist ein Fehler unterlaufen!"
                this.alert.active++
                console.error(err)
            }
            this.isLoading = false
        },
        async addPermissions() {
            if(this.addPermissionsId == "" || this.addPermissionsId.toLowerCase().replace("@itvv.org", "") == "") {
                return
            }
            this.isLoading = true
            try{
                const getUserInfo = functions.httpsCallable("getUserInfo");
                const response = await getUserInfo({
                    email: this.addPermissionsId.toLowerCase()
                })
                if(response.data.response === "ok") {
                    if(!(response.data.user.sub in this.permissions)) {
                        await firestore.collection("permissions").doc(response.data.user.sub).set({
                            family_name: response.data.user.family_name,
                            name: response.data.user.name,
                            picture: response.data.user.picture,
                            userManagement: false,
                            messageOfTheDay: false,
                        })
                    }
                    this.addPermissionsId = ""
                }else if(response.data.error === "") {
                    this.alert.title = "Berechtigungen hinzufügen"
                    this.alert.message = "Beim Hinzufügen der Berechtigung ist ein Fehler unterlaufen!"
                    this.alert.active++
                }else{
                    this.alert.title = "Berechtigungen hinzufügen"
                    this.alert.message = "Es wurde kein Login NEW Profil zu dieser A-Kennung gefunden!"
                    this.alert.active++
                    console.error(response.data)
                }
            }catch(err) {
                this.alert.title = "Berechtigungen hinzufügen"
                this.alert.message = "Beim Hinzufügen der Berechtigung ist ein Fehler unterlaufen!"
                this.alert.active++
                console.error(err)
            }
            this.isLoading = false
        }
    },
    mounted() {
        this.firebase.listener = firestore.collection("permissions").onSnapshot(docs => {
            this.permissions = {};
            docs.forEach(doc => {
                var newDoc = doc.data(); 
                newDoc._id = doc.id;
                this.$set(this.permissions, doc.id, newDoc);
            });
        })
    },
    destroyed() {
        if(this.firebase.listener != null) {
            this.firebase.listener();
            this.firebase.listener = null;
        }
    },
    watch: {
        active() {
            this.dialog = true;
        }
    },
    props: {
        active: Number
    },
    components: {
        AlertDialog
    }
}
</script>