<template>
  <v-app>
    <v-app-bar app>
      <PermissionsDialog
        :active="userManagementDialog"
        v-if="$store.state.auth.permissions.userManagement"
      ></PermissionsDialog>

      <router-link to="/">
        <v-img
          alt="NEW Logo"
          class="shrink"
          contain
          src="@/assets/logo.svg"
          transition="scale-transition"
          width="100"
        />
      </router-link>

      <v-spacer></v-spacer>
      <v-toolbar-title :class="$vuetify.breakpoint.smAndUp ? 'pr-12' : ''">
        <div class="text-sm-h5 text-truncate">Aktuelle Meldungen</div>
        <!-- Aktuelle Meldungen -->
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <div id="loginnew-dropdown"></div>

      <!-- <v-row>
        <v-col cols="2" class="d-flex justify-space-around">
          <router-link to="/">
            <v-img alt="NEW Logo" src="@/assets/logo.svg" contain width="100" />
          </router-link>
        </v-col>
        <v-col class="d-flex justify-space-around">
          <v-toolbar-title>Aktuelle Meldungen</v-toolbar-title>
        </v-col>
        <v-col cols="2" class="d-flex justify-space-around">
          <div id="loginnew-dropdown"></div>
        </v-col>
      </v-row> -->
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import PermissionsDialog from "./components/dialogs/PermissionsDialog.vue";
import Footer from "./components/Footer.vue";

export default {
  components: { PermissionsDialog, Footer },
  name: "App",
  data() {
    return {
      userManagementButtonAdded: false,
      messageOfTheDayButtonAdded: false,
      userManagementDialog: 0,
    };
  },
  watch: {
    "$store.state.auth.permissions.userManagement": {
      handler: function (permission) {
        if (permission) {
          if (!this.userManagementButtonAdded) {
            loginNew.dropdown.addButton(
              loginNew.ALWAYS,
              "btn-userManagement",
              "Rechteverwaltung",
              "mdi-shield-account",
              () => {
                this.userManagementDialog++;
              }
            );
            this.userManagementButtonAdded = true;
          }
        } else {
          if (this.userManagementButtonAdded) {
            loginNew.dropdown.removeButton("btn-userManagement");
            this.userManagementButtonAdded = false;
          }
        }
      },
      immediate: true,
    },
    "$store.state.auth.permissions.messageOfTheDay": {
      handler: function (permission) {
        if (permission) {
          if (!this.messageOfTheDayButtonAdded) {
            loginNew.dropdown.addButton(
              loginNew.ALWAYS,
              "btn-messageOfTheDay",
              "Banner bearbeiten",
              "mdi-cog",
              () => {
                this.$router.push("/editor");
              }
            );
            this.messageOfTheDayButtonAdded = true;
          }
        } else {
          if (this.messageOfTheDayButtonAdded) {
            loginNew.dropdown.removeButton("btn-messageOfTheDay");
            this.messageOfTheDayButtonAdded = false;
          }
        }
      },
      immediate: true,
    },
  },
  mounted() {},
};
</script>

<style>
#loginnew-dropdown .login-new-avatar span {
  display: none;
}

header.v-app-bar {
  contain: unset;
  transform: none !important;
  overflow: visible;
}
</style>
