import { store } from '../store/store'

import Home from '../components/Home.vue'
import Login from '../components/Login.vue'
import BannerEditor from '../components/BannerEditor.vue'

const checkAuth = (to, from, next) => {
    if (!store.state.auth.user || !store.state.auth.permissions.messageOfTheDay) {
        next('/')
    } else {
        next()
    }
}

export const routes = [
    { path: '', component: Home },
    { path: '/login', component: Login },
    { path: '/editor', component: BannerEditor, beforeEnter: checkAuth },
    { path: '/:filterGroupParam', component: Home }
]
