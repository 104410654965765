import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'

import { routes } from './routes/routes'
import { store } from './store/store'
import { auth, functions } from "./firebase/firebase";

Vue.config.productionTip = false

Vue.use(VueRouter)
const router = new VueRouter({
  routes,
  mode: 'history'
})

loginNew.init(
  process.env.VUE_APP_CLIENT_ID,
  location.protocol + "//" + location.host + "/login"
);

const AwaitForFirebase = () => {
  return new Promise((resolve) => {
    const listener = auth.onAuthStateChanged(() => {
      listener()
      resolve()
    })
  })
}

loginNew.onBeforeAuth(() => {
  localStorage.setItem('currentRoute', router.currentRoute.path)
})

loginNew.onBeforeLogout(() => {
  localStorage.setItem('currentRoute', router.currentRoute.path)
})

loginNew.onAuthStateChanged(async () => {
  store.commit("setIsAuthenticating", true);
  if (localStorage.getItem('currentRoute') != null) {
    router.push(localStorage.getItem('currentRoute'))
    localStorage.removeItem('currentRoute')
  }

  if (loginNew.isSignedIn()) {

    await AwaitForFirebase()

    if (auth.currentUser && auth.currentUser.uid == loginNew.getProfile().sub) {
      store.dispatch("setUserLogin", auth.currentUser.uid);
      store.commit('setIsAuthenticating', false)
      const tokenResult = await auth.currentUser.getIdTokenResult(true)
      store.commit("setUserClaims", tokenResult.claims)
    } else {
      const getAuthToken = functions.httpsCallable("getAuthToken");
      const response = await getAuthToken({ token: loginNew.getIdToken() });
      if (response.data.response === "OK") {
        await auth.signInWithCustomToken(response.data.token);
        store.dispatch("setUserLogin", auth.currentUser.uid);
        store.commit("setIsAuthenticating", false);
        const tokenResult = await auth.currentUser.getIdTokenResult(true)
        store.commit("setUserClaims", tokenResult.claims)
      } else {
        console.log(response.data)
      }
    }
  } else {
    store.commit("setIsAuthenticating", false);
    store.dispatch("setUserLogin", null);
    store.commit("setUserClaims", null)
    await AwaitForFirebase()
    if (auth.currentUser) {
      auth
        .signOut()
        .then(() => {
          store.commit("setUserLogout");
          console.log("signout successful")
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

});

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
