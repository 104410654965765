import { firestore } from '../../firebase/firebase'

const state = {
    isAuthenticating: true,
    user: null,
    userData: {
        selectedLines: []
    },
    permissions: {
        userManagement: false,
        messageOfTheDay: false,
    },
    firestoreListener: null,
    isLoading: false
}

const mutations = {
    setUserLogin(state, user) {
        state.user = user
    },

    setUserClaims(state, claims) {
        if (claims && claims.permissions) {
            state.permissions.userManagement = claims.permissions.userManagement === true
            state.permissions.messageOfTheDay = claims.permissions.messageOfTheDay === true
        } else {
            state.permissions.userManagement = false
            state.permissions.messageOfTheDay = false
        }
    },

    setUserLogout(state) {
        state.user = null
    },

    setIsAuthenticating(state, isAuthenticating) {
        state.isAuthenticating = isAuthenticating
    }
}

const actions = {
    setUserLogin: ({ commit }, user) => {
        commit('setUserLogin', user)
    },

    attachUserDataOnSnapshot({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.userData)
                return
            }
            state.isLoading = true
            state.firestoreListener = firestore
                .collection("userData").doc(state.user)
                .onSnapshot(doc => {
                    if (doc.exists) {
                        if (doc.data().selectedLines) {
                            state.userData.selectedLines = doc.data().selectedLines
                        } else {
                            state.userData.selectedLines = []
                        }
                    } else {
                        state.userData.selectedLines = []
                    }
                    state.isLoading = false
                    resolve(state.userData)
                }, (error) => {
                    console.log(error.message)
                    reject(error)
                })
        })
    },

    detachUserDataOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener()
            state.firestoreListener = null
        }
    }
}

export default { state, mutations, actions }
