import Vue from 'vue';
import { firestore } from '../../firebase/firebase'

const state = {
    firestoreListener: {},
    isLoading: false,
    banners: {},
    bannersWatcher: 0
}

const getters = {
    bannerCategories(state) {
        if (state.banners['allBanners']) {
            return state.banners['allBanners'].reduce((acc, cur) => {
                if (acc.findIndex((item) => item.category == cur.category) == -1) {
                    acc.push(cur.category);
                }
                return acc;
            }, []);
        }
        return []
    }
}

const actions = {
    attachBannersOnSnapshot({ state }, category) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener[category]) {
                resolve(state.banners[category])
                return
            }
            state.isLoading = true
            state.firestoreListener[category] = firestore
                .collection("banners")
                .where('category', '==', category)
                .orderBy('order')
                .onSnapshot(docs => {
                    const firebaseBanners = [];
                    docs.forEach((doc) => {
                        firebaseBanners.push(doc.data())
                    })

                    Vue.set(state.banners, category, firebaseBanners)
                    state.bannersWatcher++

                    state.isLoading = false
                    resolve(state.banners[category])
                }, (error) => {
                    console.warn(error)
                    reject(error)
                })
        })
    },

    detachBannersOnSnapshot({ state }, category) {
        if (state.firestoreListener[category]) {
            state.firestoreListener[category]()
            state.firestoreListener[category] = null
        }
    },

    attachAllBannersOnSnapshot({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener['allBanners']) {
                resolve(state.banners['allBanners'])
                return
            }
            state.isLoading = true
            state.firestoreListener['allBanners'] = firestore
                .collection("banners")
                .orderBy('order')
                .onSnapshot(docs => {
                    const firebaseBanners = [];
                    docs.forEach((doc) => {
                        const data = doc.data()
                        data.id = doc.id
                        firebaseBanners.push(data)
                    })
                    Vue.set(state.banners, 'allBanners', firebaseBanners)
                    state.bannersWatcher++
                    state.isLoading = false
                    resolve(state.banners['allBanners'])
                }, (error) => {
                    console.warn(error)
                    reject(error)
                })
        })
    },
}

export default { state, getters, actions }
