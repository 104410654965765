<template>
  <v-footer padless dark>
    <v-card flat tile width="100%" class="dark text-center">
      <v-card-text class="card-title text-h5 pt-5">
        NEW mobil und aktiv Mönchengladbach GmbH
      </v-card-text>

      <v-card-text class="card-detail pa-0">
        Odenkirchener Straße 201
      </v-card-text>
      <v-card-text class="card-detail pa-0">
        41236 Mönchengladbach
      </v-card-text>
      <v-card-text class="card-detail pa-0">
        Telefon: 02166 688 - 0
      </v-card-text>
      <v-card-text class="card-detail pa-0">
        Telefax: 02166 688 - 2445
      </v-card-text>

      <v-card-text class="card-links">
        <v-btn text href="https://www.new.de/impressum"> IMPRESSUM </v-btn>
        <v-btn text :href="datenschutzUrl"> DATENSCHUTZ </v-btn>
        <v-btn text @click="openCookieDialog">
          DATENSCHUTZ-EINSTELLUNGEN
        </v-btn>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  computed: {
    datenschutzUrl() {
      return `https://datenschutz.new.de/${process.env.VUE_APP_CLIENT_ID}`;
    },
  },

  methods: {
    openCookieDialog() {
      loginNew.showCookieDialog();
    },
  },
};
</script>
