import { firestore } from '../../firebase/firebase'

const state = {
    filterGroups: [],
    firestoreListener: null,
    isLoading: false
}

const actions = {
    attachFilterGroupsOnSnapshot({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.filterGroups)
                return
            }
            state.isLoading = true
            state.firestoreListener = firestore
                .collection("filterGroups")
                .onSnapshot(docs => {
                    const firebaseFilterGroups = [];
                    docs.forEach((doc) => {
                        firebaseFilterGroups.push({
                            ...doc.data(),
                            id: doc.id,
                        })
                    })
                    state.filterGroups = firebaseFilterGroups
                    state.isLoading = false
                    resolve(state.filterGroups)
                }, (error) => {
                    console.log(error.message)
                    reject(error)
                })
        })
    },

    detachFilterGroupsOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener()
            state.firestoreListener = null
        }
    }
}

export default { state, actions }
