<template>
  <v-container fill-height fluid style="height: 100%">
    <v-row class="text-center">
      <v-col cols="12">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    signIn() {
      loginNew.auth();
    },
  },
  watch: {
    // Reagieren auf den Anmeldestatus
    // immediate = die Aktion wird immer mindestens 1 mal aufgerufen und nach jeder Änderung
    "$store.state.auth.isAuthenticating": {
      handler: function (isAuthenticating) {
        console.log(isAuthenticating);
        if(!isAuthenticating) {
          // Verlinkung auf die Startseite
          this.$router.push("/");
        }
      },
      immediate: true,
    },
  },
};
</script>
