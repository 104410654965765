<template>
  <v-card elevation="2" outlined tile class="mx-auto mb-5">
    <div class="card-date-holder">
      <div class="card-date px-4 white--text primary elevation-1">
        {{ visibleDate }}
      </div>
    </div>
    <v-card-title style="word-break: break-word;">
        {{ message.line }} - {{ message.title }}
    </v-card-title>
    <v-card-text>
      <p v-html="message.content"></p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    message: Object,
  },

  computed: {
    visibleDate() {
      const toDate = this.message.time_to
        ? this.message.time_to.toDate()
        : this.message.visible_to.toDate();
      const toDay = toDate.getDate();
      const toMonth = toDate.getMonth();
      const toYear = toDate.getYear();
      if (this.message.time_from || this.message.visible_from) {
        const fromDate = this.message.time_from
          ? this.message.time_from.toDate()
          : this.message.visible_from.toDate();
        const fromDay = fromDate.getDate();
        const fromMonth = fromDate.getMonth();
        const fromYear = fromDate.getYear();
        if (fromDay == toDay && fromMonth == toMonth && fromYear == toYear) {
          const fromTime = `${
            fromDate.getHours() < 10
              ? "0" + fromDate.getHours()
              : fromDate.getHours()
          }:${
            fromDate.getMinutes() < 10
              ? "0" + fromDate.getMinutes()
              : fromDate.getMinutes()
          }`;
          const toTime = `${
            toDate.getHours() < 10 ? "0" + toDate.getHours() : toDate.getHours()
          }:${
            toDate.getMinutes() < 10
              ? "0" + toDate.getMinutes()
              : toDate.getMinutes()
          }`;
          return (
            "Von " +
            fromTime +
            " Uhr bis " +
            toTime +
            " Uhr am " +
            toDate.toLocaleDateString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          );
        } else {
          return (
            "Von " +
            fromDate.toLocaleDateString("de-DE", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }) +
            " bis " +
            toDate.toLocaleDateString("de-DE", {
              weekday: "short",
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          );
        }
      }
      return (
        "Bis " +
        toDate.toLocaleDateString("de-DE", {
          weekday: "short",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      );
    },
  },
};
</script>

<style scoped>
.card-date-holder {
  margin-top: -10px;
  position: relative;
}

.card-date {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}
</style>
