<template>
  <v-container v-if="isSubmitted">
    <v-progress-linear indeterminate></v-progress-linear>
  </v-container>
  <v-container v-else>
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="selectedBannerCategory"
          :items="bannerCategories"
          label="Banner auswählen"
          solo
          no-data-text="Keine Daten verfügbar"
        ></v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn @click="addBanner">+</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-row no-gutters>
            <v-col
              v-for="(banner, index) in filteredBanners"
              :key="'banner_' + index"
              :cols="banner.type == 'column' ? '6' : '12'"
            >
              <v-toolbar dense outlined flat>
                <v-btn
                  :color="banner.type == 'column' ? 'primary' : null"
                  small
                  @click="$set(banner, 'type', 'column')"
                >
                  2 Spalte
                </v-btn>
                <v-btn
                  :color="banner.type != 'column' ? 'primary' : null"
                  small
                  @click="$set(banner, 'type', 'default')"
                >
                  1 Spalte
                </v-btn>
                <v-btn small @click="$set(banner, 'delete', true)">
                  Entfernen
                </v-btn>
              </v-toolbar>
              <vue-editor
                v-model="banner.content"
                :editor-toolbar="customToolbar"
              ></vue-editor>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-switch v-model="categorySettings.hideMessages" inset label="Alle Nachrichten ausblenden, wenn dieser Banner angezeigt wird." persistent-hint hint="Dadurch werden alle Nachrichten, die normalerweise unter dem Banner angezeigt werden, ausgeblendet."></v-switch>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-card-actions>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="cancel" text>Zurück</v-btn>
              <v-btn @click="save" text :disabled="isSubmitted">
                Speichern
              </v-btn>
              <slot></slot>
            </v-card-actions>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" text>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Schließen
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { firestore } from "../firebase/firebase";

export default {
  components: {
    VueEditor,
  },

  data() {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [
          {
            color: [
              "#9B004B",
              "#6E6E6E",
              "#FFFFFF",
              "#000000",
              "#302C81",
              "#22478A",
              "#006CAD",
              "#7BBDEC",
              "#791D75",
              "#C20016",
            ],
          },
          {
            background: [
              "#9B004B",
              "#6E6E6E",
              "#FFFFFF",
              "#000000",
              "#302C81",
              "#22478A",
              "#006CAD",
              "#7BBDEC",
              "#791D75",
              "#C20016",
            ],
          },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ script: "sub" }, { script: "super" }],
        ["link", "image", "video"],
        ["clean"],
      ],
      sideContents: 0,
      isSubmitted: false,
      snackbar: false,
      snackbarText: "",
      contents: 0,
      selectedBannerCategory: "main",
      categorySettings: {
        hideMessages: false
      },
      bannerArray: [],
      addedBanner: [],
    };
  },
  watch: {
    currentBannerSettings(currentBannerSettings) {
      this.categorySettings.hideMessages = currentBannerSettings.hideMessages === true
    }
  },
  computed: {
    banners() {
      if (this.$store.state.banners.isLoading) {
        return [];
      }
      if (!this.$store.state.banners.banners["allBanners"]) {
        return [];
      }
      return this.$store.state.banners.banners["allBanners"];
    },
    currentBannerSettings() {
      if (this.$store.state.categories.categories[this.selectedBannerCategory]) {
        return this.$store.state.categories.categories[this.selectedBannerCategory]
      }
      return {}
    },
    filteredBanners() {
      const banners = [];
      this.banners.forEach((banner) => {
        if (banner.category == this.selectedBannerCategory && !banner.delete)
          banners.push(banner);
      });

      this.addedBanner.forEach((banner) => {
        if (!banner.delete) {
          banners.push(banner);
        }
      });

      return banners;
    },

    bannerCategories() {
      const categories = [];
      this.banners.forEach((banner) => {
        if (categories.indexOf(banner.category) == -1)
          categories.push(banner.category);
      });
      return categories;
    },

    deletedBanners() {
      const banners = [];
      this.banners.forEach((banner) => {
        if (banner.category == this.selectedBannerCategory && banner.delete)
          banners.push(banner);
      });
      return banners;
    },
  },

  methods: {
    cancel() {
      this.$router.push("/");
    },

    save() {
      this.isSubmitted = true;
      const firestoreRef = firestore.collection("banners");

      const deleteBatch = firestore.batch();
      let order = 0;
      this.filteredBanners.forEach((banner) => {
        banner.order = order;
        if (banner.id) {
          deleteBatch.update(firestoreRef.doc(banner.id), banner);
        } else {
          deleteBatch.set(firestoreRef.doc(), banner);
        }
        order++;
      });

      this.deletedBanners.forEach((banner) => {
        deleteBatch.delete(firestoreRef.doc(banner.id));
      });

      deleteBatch.set(firestore.collection("categories").doc(this.selectedBannerCategory), {
        hideMessages: this.categorySettings.hideMessages
      }, { merge: true })

      deleteBatch
        .commit()
        .then(() => {
          this.isSubmitted = false;
          this.snackbarText = "Banner gespeichert!";
          this.snackbar = true;
          this.addedBanner = [];
        })
        .catch((err) => {
          this.isSubmitted = false;
          this.snackbarText =
            "Es ist ein Fehler aufgetreten. Bitte versuche es später noch einmal";
          this.snackbar = true;
          console.warn(err);
        });
    },

    addBanner() {
      this.addedBanner.push({
        content: "",
        category: this.selectedBannerCategory,
        order: this.filteredBanners.length,
      });
    },
  },

  mounted() {
    this.$store.dispatch("attachAllBannersOnSnapshot");
    this.$store.dispatch("attachAllCategorySettingsOnSnapshot").then(() => {
      this.categorySettings.hideMessages = this.currentBannerSettings.hideMessages === true
    });
  },
};
</script>
